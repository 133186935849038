import React, { useState, useEffect } from 'react'
import { PrimaryTitles, SecondaryTitles } from "../../shared/TitleTexts";
import PageContainer from "../../components/PageContainer";
import { MyDnaButton } from "../../components/MyDnaButton";
import MyDnaTextField from "../../components/MyDnaTextField";
import { AuthenticationTitle } from "../../shared/TitleTexts.js";
import { Link } from "@material-ui/core";
import { selectEmailAddress } from "../../store/userSlice";
import { forgotPassword } from "../forgotPassword/ForgotPasswordServices"
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { forgotPasswordConfirmation } from './ForgotPasswordServices'
import { messages } from "../../shared/Messages";

const ForgotPassword = () => {
    const [verificationCode, setVerificationCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const email = useSelector(selectEmailAddress);
    const [errorMessage, setErrorMessage] = useState(null)
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        !email && history.push('/')
    })
    
    const forgotPasswordConf = (event) => {
        event.preventDefault()
        forgotPasswordConfirmation(email, verificationCode, password, confirmPassword, setErrorMessage,
            dispatch, history)
    }

    const forgotPasswordResend = (event) => {
        event.preventDefault();
        forgotPassword(email, setErrorMessage, dispatch, history);
    }

    return (
        <div>
            <PageContainer
                formId={'forgot_password'}
                primaryTitle={PrimaryTitles.SignIn}
                secondaryTitle={SecondaryTitles.SignIn}
                onSubmit={(event) => forgotPasswordConf(event)}
                authenticationProgress={AuthenticationTitle.SignIn.progress}
                authenticationTitle={AuthenticationTitle.SignIn.title}
                content={
                    <React.Fragment>
                        <MyDnaTextField
                            className="mydna-input"
                            id="verificationCode"
                            name="verificationCode"
                            onChange={(e) => setVerificationCode(e.target.value)}
                            type="text"
                            label="Verification Code"
                            required={true}
                            value={verificationCode} />

                        <MyDnaTextField
                            className="mydna-input"
                            id="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            type="Password"
                            label="Password"
                            required={true}
                            value={password} />

                        <MyDnaTextField
                            className="mydna-input"
                            id="confirmPassword"
                            name="confirmPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type="Password"
                            label="Confirm Password"
                            required={true}
                            value={confirmPassword} />
                        <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        {errorMessage && <div>
                            <p className="error">{errorMessage}</p>
                        </div>}
                        <MyDnaButton submitText="Change Password" />
                        <Link
                            component="button"
                            type="button"
                            onClick={(event) => forgotPasswordResend(event)}>
                            <p className={'reset-link'}>Resend Verification Code</p>
                        </Link>
                    </React.Fragment>
                }
            />
        </div>
    )
}

export default ForgotPassword
