import React from 'react'
import PropTypes from 'prop-types'
import MobileStepper from '@material-ui/core/MobileStepper';

const Stepper = props => {
    return (
        <div>
            <MobileStepper
                style={{ width: '20rem', justifyContent: "center", margin: '0 auto', backgroundColor: "#FFF"}}
                variant="progress"
                steps={8}
                position="static"
                activeStep={props.stepNum}
            />
        </div>
    )
}

Stepper.propTypes = {
    stepNum: PropTypes.number
}

export default Stepper
