import { createStore, combineReducers, applyMiddleware } from "redux";
import userReducer from './userSlice';
import { Logger } from './middlewares/Logger';
import { composeWithDevTools } from 'redux-devtools-extension';
const combinedReducers = combineReducers({
  user: userReducer
});

const combinedStore = createStore(
  combinedReducers,
  composeWithDevTools(applyMiddleware(Logger))
);

export default combinedStore;
