import React from 'react'
import Header from "./Header";
import AuthenticationStepper from "./AuthenticationStepper";

const PageContainer = ({ content, primaryTitle, secondaryTitle, onSubmit, formId, authenticationTitle, authenticationProgress }) => {
    return (
        <React.Fragment>
            <AuthenticationStepper title={'Account Management'} progress={authenticationProgress} />
            <Header primaryTitle={primaryTitle} secondaryTitle={secondaryTitle} />
            <div className="content-container">
                <form onSubmit={onSubmit} id={formId}>
                    {content}
                </form>
            </div>
        </React.Fragment>
    )
}

export default PageContainer
