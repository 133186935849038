import React from 'react'
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

export const MyDnaButton = (props) => {
    return (
        <Button
            disabled={props.disabled}
            className={props.className || 'SubmitButton'}
            type={props.type || 'submit'}
            value={props.value || 'submit'}
            variant="contained"
            color="primary"
        >{props.disabled === true ?
            <CircularProgress size={25} color="secondary" /> : props.submitText ?? 'NEXT'}
        </Button>
    )
}

MyDnaButton.propTypes = {
    submitText: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool
}