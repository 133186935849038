import React from 'react';
import './App.css';
import SignIn from "./features/signIn/SignIn";
import ForgotPassword from "./features/forgotPassword/ForgotPassword";
import ForgotPasswordEmail from "./features/forgotPasswordEmail/ForgotPasswordEmail";
import Complimentary from "./features/complimentary/Complimentary";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Amplify from "aws-amplify";
import MyDnaMenuAppBar from "./components/MyDnaMenuAppBar";

function App() {
  const awsConfig = require("./config/aws-exports.json");
  const awsCognitoConfig = require("./config/aws-cognito-config.json");
  const appSyncConfig = require("./config/aws-appsync-config.json");

  const amplifyConfig = Object.assign({}, awsConfig, awsCognitoConfig);
  Amplify.configure(amplifyConfig);
  Amplify.configure(appSyncConfig);
  require("dotenv").config();

  return (
    <React.Fragment>
      <MyDnaMenuAppBar />
      <Router>
        <Switch>
          <Route exact path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/forgotPasswordEmail">
            <ForgotPasswordEmail />
          </Route>
          <Route exact path="/complimentary">
            <Complimentary />
          </Route>
          <Route path="/">
            <SignIn />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
