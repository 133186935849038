import { Auth } from "aws-amplify";
import {
    SIGNED_IN,
    SIGN_IN_FAILURE
} from '../../store/userSlice';
import { messages } from "../../shared/Messages";
import { request } from "../../api/index";
import { getUser } from "../../graphql/queries";
export function signIn(email, password, setErrorMessage, dispatch, setDisabled, history) {
    if (!validateEmail(email)) {
        setErrorMessage(messages.pleaseCheckYourEmail)
    }
    else {
        Auth.signIn(email, password)
            .then(async (data) => {
                setErrorMessage(null)
                let redirectionData = await getUserRedirectData()
                dispatch(SIGNED_IN({ emailAddress: data.username, acessToken: data.signInUserSession.accessToken.jwtToken, redirectionData: redirectionData  }))
                if (redirectionData?.isComplimentarySubscription) {
                    history.push('/complimentary')
                } else {
                    window.location = redirectionData.paymentPortalUrl
                }
            })
            .catch((error) => {
                if (error?.code === 'UserNotFoundException') {
                    setErrorMessage(messages.userNotFound)
                } else {
                    dispatch(SIGN_IN_FAILURE(error?.message))
                    setErrorMessage(error?.message)
                }
            })
            .finally(() => {
                setDisabled(false)
            })
    }
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

async function getUserRedirectData() {
    let content = await request(getUser, {}, "getUser");
    return content.partnerPortalData;
}