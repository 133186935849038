import { Auth } from "aws-amplify";
import { validateEmail } from "../signIn/SignInServices";
import { messages } from "../../shared/Messages";
import {
    FORGOT_PASSWORD
} from '../../store/userSlice';

export function forgotPassword(email, setErrorMessage, dispatch, history) {
    if (!validateEmail(email)) {
        setErrorMessage(messages.pleaseCheckYourEmail)
    }
    else {
        Auth.forgotPassword(email)
            .then((output) => {
                setErrorMessage(null)
                dispatch(FORGOT_PASSWORD(email))
                history.push('/forgotPassword')
            })
            .catch((error) => {
                if (error?.code === "UserNotFoundException") {
                    setErrorMessage(messages.userNotFound)
                } else {
                    setErrorMessage(error?.message)
                }
            })
    }
}