import { Auth } from "aws-amplify";
import { messages } from "../../shared/Messages";
import { validateEmail } from "../signIn/SignInServices";
import {
    SIGNED_IN,
    SIGN_IN_FAILURE
} from '../../store/userSlice';

export function forgotPasswordConfirmation(email, confirmationCode, password, confirmPassword, setErrorMessage, dispatch, history) {
    if (password === confirmPassword) {
        Auth.forgotPasswordSubmit(email, confirmationCode, password)
            .then((data) => {
                Auth.signIn(email, password)
                    .then((data) => {
                        setErrorMessage(null)
                        dispatch(SIGNED_IN(data))
                        //ToDo: handle business logic on signing in
                    })
                    .catch((error) => {
                        dispatch(SIGN_IN_FAILURE(error?.message))
                        setErrorMessage(error?.message)
                    })
            })
            .catch((error) => {
                if (error.code === "InvalidParameterException" || error.code === "InvalidPasswordException") {
                    setErrorMessage(messages.invalidPassword)
                } else {
                    setErrorMessage(error?.message)
                }
            })
    } else {
        setErrorMessage(messages.passwordDoesNotMatch)
    }

}

export function forgotPassword(email, setErrorMessage, dispatch, history) {
    if (!validateEmail(email)) {
        setErrorMessage(messages.pleaseCheckYourEmail)
    }
    else {
        Auth.forgotPassword(email)
            .then((output) => {
                setErrorMessage(null)
            })
            .catch((error) => {
                if (error?.code === "UserNotFoundException") {
                    setErrorMessage(messages.userNotFound)
                } else {
                    setErrorMessage(error?.message)
                }
            })
    }
}