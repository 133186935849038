import { API, graphqlOperation } from "aws-amplify";

export async function request(query, variables, keypath) {
  try {
    const response = await API.graphql(graphqlOperation(query, variables));
    if (keypath) {
      return keypath
        .split(".")
        .reduce((previous, current) => previous[current], response.data);
    }
    return response.data;
  } catch (error) {
    //ToDo: Add error handling
  }
}