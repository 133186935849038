import React, { useState } from 'react'
import { MyDnaButton } from "../../components/MyDnaButton";
import MyDnaTextField from "../../components/MyDnaTextField";
import { messages } from "../../shared/Messages";
import { signIn } from "./SignInServices";
import { useDispatch } from 'react-redux';
import { PrimaryTitles, SecondaryTitles } from "../../shared/TitleTexts";
import PageContainer from "../../components/PageContainer";
import { AuthenticationTitle } from "../../shared/TitleTexts.js";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FORGOT_PASSWORD } from '../../store/userSlice';

const SignIn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const dispatch = useDispatch();
    let history = useHistory();
    function signInUser(event) {
        event.preventDefault()
        setDisabled(true)
        signIn(email, password, setErrorMessage, dispatch, setDisabled, history)
        event.preventDefault()
    }
    function forgotPassword(event) {
        dispatch(FORGOT_PASSWORD(email))
        history.push('/forgotPasswordEmail')
    }
    return (
        <div>
            <PageContainer
                formId={'sign_in'}
                primaryTitle={PrimaryTitles.SignIn}
                secondaryTitle={SecondaryTitles.SignIn}
                onSubmit={signInUser}
                authenticationProgress={AuthenticationTitle.SignIn.progress}
                authenticationTitle={AuthenticationTitle.SignIn.title}
                content={
                    <React.Fragment>
                        <MyDnaTextField
                            className="mydna-input"
                            id="email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            label="Email"
                            required={true}
                            value={email} />
                        <MyDnaTextField
                            className="mydna-input"
                            id="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            variant="outlined"
                            label="Password"
                            required={true}
                            value={password} />
                        <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        {errorMessage && <div>
                            <p className="error" dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
                        </div>}
                        <MyDnaButton submitText="Login" disabled={disabled}/>
                        <Link
                            component="button"
                            type="button"
                            onClick={(event) => forgotPassword(event)}>
                            <p className={'reset-link'}>Forgot password?</p>
                        </Link>
                    </React.Fragment>
                } />
        </div>
    )
}

export default SignIn
