import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { selectEmailAddress, selectUserRedirectData } from "../../store/userSlice";
import { useHistory } from "react-router-dom";
const Complimentary = () => {
    let history = useHistory();
    useEffect(() => {
        !email && history.push('/')
    })
    
    const redirectionData = useSelector(selectUserRedirectData);
    const email = useSelector(selectEmailAddress);
    return (
        <div className="complimentary-container">
            <p>
                Hi {redirectionData?.firstName},
            </p>
            <p>
                You have a complimentary subscription.
            </p>
            <p>
                You can access all your plans and insights via the myDNA Unlocked app.
            </p>
        </div>
    )
}

export default Complimentary
