export const SecondaryTitles =  {
    SignIn : "Our wellness Platform members can login here to manage their subscription and payment details",
}
export const PrimaryTitles =  {
    Create:"YOUR DNA-POWERED JOURNEY STARTS HERE!",
    Verify:"Nice to meet you, #$#$. Please verify your email",
    SignIn:'manage your subscription',
    Confirmation:'CONGRATULATIONS!'
}

export const AuthenticationTitle={
    SignIn: {title:'CREATE AN ACCOUNT', progress:0},
    ForgotPassword: { title:'FORGOT PASSWORD', progress:0 },
    ForgotPasswordConfirmation: { title:'CHANGE PASSWORD', progress:0 },
}
