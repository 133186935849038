import React from 'react'
import PropTypes from 'prop-types'

const Header = props => {
    const title = React.useRef(null);
    React.useEffect(() => {
        title.current && title.current.focus();
    })
    return (
        <div ref={title}>
            <h2 className={"mydna-primary-title"}>{props.primaryTitle}</h2>
            <p className={"mydna-secondary-title"} dangerouslySetInnerHTML={{ __html: props.secondaryTitle }} />
        </div>
    )
}

Header.propTypes = {
    primaryTitle: PropTypes.string,
    secondaryTitle: PropTypes.string
}

export default Header
