import React from "react";
import Stepper from "./Stepper";
const AuthenticationStepper = (props) => {
    return (
        <div>
            <div className="title">{props.title}</div>
            <Stepper stepNum={props.progress} />
        </div>
    );
};

export default AuthenticationStepper;