import React from 'react';
import {AppBar, Toolbar} from "@material-ui/core";
import MyDnaLogo from "../assets/images/mydna-logo-white.png";

const MyDnaMenuAppBar = () => {
    return (
            <AppBar position="static" className={'app-bar'} >
                <Toolbar>
                    <img src={MyDnaLogo} alt={'mydna logo'} className={'mydna-logo'}/>
                </Toolbar>
            </AppBar>
    )
}

export default MyDnaMenuAppBar