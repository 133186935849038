import React from 'react'
import { TextField } from "@material-ui/core";
import PropTypes from 'prop-types';

const MyDnaTextField = (props) => {
    return (
        <TextField
            className="mydna-input"
            id={props.id}
            name={props.name}
            onChange={props.onChange}
            type={props.type}
            variant="outlined"
            label={props.label}
            required={props.required}
            value={props.value}
        />
    )
}

MyDnaTextField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
}

export default MyDnaTextField
