import LocalizedStrings from 'react-localization';

export const messages = new LocalizedStrings({
    en: {
        loginFailed: 'Login failed, Please try again.',
        passwordDoesNotMatch: "Password doesn't match.",
        pleaseCheckYourEmail: "Please enter a valid email address",
        confirmationSuccessful: "Confirmation was successful, You will be redirected soon.",
        codeResent: "Code resent. Please check your email.",
        userExistsErrorMessage: "An account with this email already exists. Please try logging in. Alternatively, reset your password.",
        invalidPassword: "Password should contain a minimum of 8 characters, including upper case and lower case letters.",
        passwordResetCode:"If you have an account, verification code has been sent to your email.",
        changedPassword: "Password change successful please login with new credentials.",
        userNotFound:`Oops it looks like you don’t yet have a subscription with us.  You can subscribe <a href="http://subscribe.mydna.life">here.</a>`,
        CodeMismatchException:"Invalid verification code provided, please try again.",
        signUpFailed:"Sign up failed, please try again.",
        userNotConfirmedException:"Please confirm your email address",
        notAuthorizedException:"Incorrect username or password.",
        InvalidParameterException:'Cannot reset password for the user as there is no registered/verified email or Phone number.',

    }
});
