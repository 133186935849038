import React, { useState } from 'react'
import { PrimaryTitles, SecondaryTitles } from "../../shared/TitleTexts";
import PageContainer from "../../components/PageContainer";
import { MyDnaButton } from "../../components/MyDnaButton";
import MyDnaTextField from "../../components/MyDnaTextField";
import { AuthenticationTitle } from "../../shared/TitleTexts.js";
import { selectEmailAddress } from "../../store/userSlice";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { forgotPassword } from './ForgotPasswordEmailServices'

const ForgotPasswordEmail = () => {
    const email = useSelector(selectEmailAddress);
    const [errorMessage, setErrorMessage] = useState(null)
    const [emailAddress, setEmailAddress] = useState(email)
    const dispatch = useDispatch();
    let history = useHistory();
    
    return (
        <PageContainer
            formId={'forgot_password'}
            primaryTitle={PrimaryTitles.SignIn}
            secondaryTitle={SecondaryTitles.SignIn}
            onSubmit={(event) => {
                event.preventDefault()
                forgotPassword(emailAddress, setErrorMessage, dispatch, history)
            }}
            authenticationProgress={AuthenticationTitle.SignIn.progress}
            authenticationTitle={AuthenticationTitle.SignIn.title}
            content={
                <React.Fragment>
                    <MyDnaTextField
                        className="mydna-input"
                        id="email"
                        name="email"
                        onChange={(e) => setEmailAddress(e.target.value)}
                        type="text"
                        label="Email"
                        required={true}
                        value={emailAddress} />
                    {errorMessage && <div>
                        <p className="error">{errorMessage}</p>
                    </div>}
                    <MyDnaButton submitText="Send verification email" />
                </React.Fragment>
            }
        />
    )
}

export default ForgotPasswordEmail
